<template>
  <iframe :src="gameUrl" :height="height">

  </iframe>
</template>

<script>

import { tokenCheck } from '@/libs/auth-helper'
import { mapState } from 'vuex'

export default {
  name: 'SlotsGameLink',
  data () {
    return {
      width: '100%',
      height: window.innerHeight,
      gameUrl: ''
    }
  },
  computed: {
    ...mapState([
      'userData'
    ])
  },
  mounted () {
    document.body.style = 'position: fixed;width:100vw;height:100%'
    window.addEventListener('resize', this.handleResize)
    setInterval(async () => {
      console.log('game popup')
      if (this.userData) {
        await tokenCheck()
      } else {
        await this.onClose()
      }
    }, 10000, this)

    this.emitter.on('gameDown', async (data) => {
      if (data) {
        await this.onClose()
      }
    })
  },
  created () {
    this.width = window.innerWidth
    this.height = window.innerHeight

    setTimeout(() => {
      if (this.userData) {
        this.gameUrl = this.$route.query.url
        console.log('gameUrl : ', this.gameUrl)
      }
    }, 500)

    this.handleResize()
  },
  methods: {
    handleResize () {
      // setTimeout(function () {
      this.width = window.innerWidth
      this.height = window.innerHeight
      // }, 200)
    },
    async onClose () {
      await this.onCheck('게임을 할 수 없는 상태입니다. 고객센터에 문의하세요')
      self.close()
    }
  }
}
</script>
<style scoped>

iframe {
  overflow: hidden;
  position: fixed;
  width: 100vw;
  height: 100%;
}
</style>
